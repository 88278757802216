import React from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';

import LoginIcon from '@material-ui/icons/LockOpen';
import RecoverIcon from '@material-ui/icons/Security';
import AboutIcon from '@material-ui/icons/Info';
import ScanIcon from '@material-ui/icons/PhotoCamera';

import ResolveQR from '../ResolveQR';
import GenericView from './GenericView';

import './PublicView.scss';

const Recover = loadable(() => import(/* webpackChunkName: "Recover" */'../scenes/Recover'));
const SetPassword = loadable(() => import(/* webpackChunkName: "SetPassword" */'../scenes/SetPassword'));
const About = loadable(() => import(/* webpackChunkName: "About" */'../scenes/About'));
const Scan = loadable(() => import(/* webpackChunkName: "Scan" */'../scenes/Scan'));
const ScanFailure = loadable(() => import(/* webpackChunkName: "ScanFailure" */'../scenes/ScanFailure'));
const ViewonlyScaffolding = loadable(() => import(/* webpackChunkName: "ViewonlyScaffolding" */'../scenes/ViewonlyScaffolding'));
const Login = loadable(() => import(/* webpackChunkName: "Login" */'../scenes/Login'));

const menuItems = [
  {
    link: '/public/login',
    icon: <LoginIcon />,
    text: 'menu:login',
  }, {
    link: '/public/scan',
    icon: <ScanIcon />,
    text: 'menu:scan',
  }, {
    link: '/public/recover',
    icon: <RecoverIcon />,
    text: 'menu:recover',
  }, {
    link: '/public/about',
    icon: <AboutIcon />,
    text: 'menu:about',
  },
];

const PublicView = () => (
  <GenericView menuItems={menuItems}>
    <Switch>
      <Route path="/public/scaffolding/:id/:code/:section?" component={ViewonlyScaffolding} />
      <Route exact path="/public/scan/failure" component={ScanFailure} />
      <Route exact path="/public/scan/:qr?" component={Scan} />
      <Route exact path="/public/about" component={About} />
      <Route path="/public/recover/:hash" component={SetPassword} />
      <Route path="/public/recover" component={Recover} />
      <Route path="/public/login" component={Login} />
      <Route component={ResolveQR} />
    </Switch>
  </GenericView>
);

export default PublicView;
