/* eslint-disable max-classes-per-file */
import config from '../config';

class ScaffoldingHelper {
  static getArea = scaffolding => {
    if (scaffolding
      && scaffolding.length
      && scaffolding.height
      && scaffolding.length.match(/^\d+(\.\d+)?$/)
      && scaffolding.height.match(/^\d+(\.\d+)?$/)
    ) {
      return (
        parseFloat(scaffolding.length)
        * parseFloat(scaffolding.height)
      ).toFixed(2);
    }
    return '-';
  };

  static getVolume = scaffolding => {
    if (scaffolding
      && scaffolding.width
      && scaffolding.height
      && scaffolding.length
      && scaffolding.width.match(/^\d+(\.\d+)?$/)
      && scaffolding.length.match(/^\d+(\.\d+)?$/)
      && scaffolding.height.match(/^\d+(\.\d+)?$/)
    ) {
      return (
        parseFloat(scaffolding.width)
        * parseFloat(scaffolding.height)
        * parseFloat(scaffolding.length)
      ).toFixed(2);
    }
    return '-';
  };

  static getStaticDocuments(lang) {
    const urlBase = `https://s3-eu-west-1.amazonaws.com/${config.s3bucket}/public_files/`;
    const items = {
      en: [[
        `${urlBase}Kuormaluokkien+ta%CC%88ytto%CC%88+-+Use+of+load+classes+FIN_ENG.pdf`,
        'Use of load classes',
        'application/pdf',
      ], [
        `${urlBase}Safe+use+of+scaffolds+ENG.pdf`,
        'Safe use of scaffoldings',
        'application/pdf',
      ]],
      fi: [[
        `${urlBase}Kuormaluokkien+ta%CC%88ytto%CC%88+-+Use+of+load+classes+FIN_ENG.pdf`,
        'Kuormaluokkien käyttö',
        'application/pdf',
      ], [
        `${urlBase}Turvallisesti+telineilla%CC%88+FIN.pdf`,
        'Turvallisesti telineillä',
        'application/pdf',
      ]],
    };
    return items[lang];
  }
}

class Logger {
  static enabled = false;

  static toggle(s) {
    this.loggerEnabled = !(!s);
    if (this.loggerEnabled) {
      this.debug('Logger enabled');
    }
  }

  static debug(msg) {
    if (this.loggerEnabled && window && window.console && window.console.info) {
      window.console.info(msg);
    }
  }
}

class LocalStorage {
  static get(k) {
    let value = null;
    try {
      value = JSON.parse(localStorage.getItem(k));
    } catch (e) {
      value = null;
    }
    return value;
  }

  static set(k, v) {
    if (v === null || v === undefined) {
      return LocalStorage.clear(k);
    }
    return localStorage.setItem(k, JSON.stringify(v));
  }

  static clear(k) {
    localStorage.removeItem(k);
  }
}

const toFormData = (data, formData = undefined, previousKey = undefined) => {
  Object.entries(data).forEach(([key, val]) => {
    const newKey = (previousKey ? `${previousKey}[${key}]` : `${key}`);
    if (val instanceof File) {
      formData.append(newKey, val);
      return;
    }
    if (val instanceof Object && !Array.isArray(val)) {
      toFormData(val, formData, key);
      return;
    }
    if (Array.isArray(val)) {
      val.forEach(val2 => {
        formData.append(`${newKey}[]`, val2);
      });
    } else {
      formData.append(newKey, val);
    }
  });
};

const resolve = (path, obj, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }
  return false;
};

export {
  LocalStorage,
  Logger,
  ScaffoldingHelper,
  toFormData,
  resolve,
  isIE,
};
