import config from '../config';

class FetchRequest {
  static token = null;
  static role = null;

  static defaultRequest = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
  };

  static getToken = () => FetchRequest.token;
  static setToken = t => (FetchRequest.token = t);
  static clearToken = () => (FetchRequest.token = null);

  static getRole = () => FetchRequest.role;
  static setRole = r => (FetchRequest.role = r);
  static clearRole = () => (FetchRequest.role = null);

  static addRole = obj => {
    if (FetchRequest.role) {
      /* eslint-disable no-param-reassign */
      obj.headers['X-Role'] = FetchRequest.role;
      /* eslint-enable no-param-reassign */
    }
  };

  static addToken = obj => {
    if (FetchRequest.token) {
      /* eslint-disable no-param-reassign */
      obj.headers.Authorization = `Bearer ${FetchRequest.token}`;
      /* eslint-enable no-param-reassign */
    }
  };

  static get(url) {
    const request = {
      ...FetchRequest.defaultRequest,
      method: 'GET',
    };
    FetchRequest.addToken(request);
    FetchRequest.addRole(request);
    return fetch(FetchRequest.createUrl(url), request)
      .then(FetchRequest.decodeResponse)
      .catch(FetchRequest.decodeException);
  }

  static post(url, data) {
    const request = {
      ...FetchRequest.defaultRequest,
    };
    if (data instanceof FormData) {
      request.body = data;
      delete request.headers['Content-Type'];
    } else {
      request.body = JSON.stringify(data);
    }
    FetchRequest.addToken(request);
    FetchRequest.addRole(request);
    return fetch(FetchRequest.createUrl(url), request)
      .then(FetchRequest.decodeResponse)
      .catch(FetchRequest.decodeException);
  }

  static put = (url, data) => {
    const request = {
      ...FetchRequest.defaultRequest,
      method: 'PUT',
    };
    if (data instanceof FormData) {
      request.body = data;
      delete request.headers['Content-Type'];
    } else {
      request.body = JSON.stringify(data);
    }
    FetchRequest.addToken(request);
    FetchRequest.addRole(request);
    return fetch(FetchRequest.createUrl(url), request)
      .then(FetchRequest.decodeResponse)
      .catch(FetchRequest.decodeException);
  }

  static remove(url) {
    const request = {
      ...FetchRequest.defaultRequest,
      method: 'DELETE',
    };
    FetchRequest.addToken(request);
    FetchRequest.addRole(request);
    return fetch(FetchRequest.createUrl(url), request)
      .then(FetchRequest.decodeResponse)
      .catch(FetchRequest.decodeException);
  }

  static postFiles(url, data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    const request = {
      ...FetchRequest.defaultRequest,
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    };
    FetchRequest.addToken(request);
    FetchRequest.addRole(request);
    return fetch(FetchRequest.createUrl(url), request)
      .then(FetchRequest.decodeResponse)
      .catch(FetchRequest.decodeException);
  }

  static createUrl = url => {
    if (url.startsWith('/')) {
      return `${config.apiUrl}${url}`;
    }
    return url;
  }

  static async decodeResponse(response) {
    const text = await response.clone().text();
    if (response.status < 500 && response.status > 399 && text.trim() === '') {
      // special case for this particular api
      return {
        error: true,
        code: response.status,
        message: 'Failure',
      };
    }
    if (response.status < 200 || response.status > 299) {
      return {
        error: true,
        code: response.status,
        message: FetchRequest.decodeError(await response.json()),
      };
    }
    return {
      error: false,
      code: response.status,
      message: await response.json(),
    };
  }

  static decodeError(json) {
    const ret = {};
    if (json.errors) {
      Object.keys(json.errors).forEach(key => {
        if (json.errors[key][0].match(/has already been taken.$/)) {
          ret[key] = 'duplicate';
        } else {
          ret[key] = 'invalid';
        }
      });
      return ret;
    }
    if (json.message) {
      return json.message;
    }
    return 'Unknown error';
  }

  static decodeException(e) {
    window.console.log('exception', e);
    return new Promise(res => {
      res({
        error: true,
        code: 0,
        message: 'Network error',
      });
    });
  }
}

export default FetchRequest;
