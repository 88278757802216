import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
// import { withTheme } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import loadable from '@loadable/component';

import { isIE } from '../lib/Helper';
import PublicView from './views/PublicView';
import UserView from './views/UserView';

// import config from '../config';

import BusyIndicator from './BusyIndicator';
import FadeTransitionGroup from './transitions/FadeTransitionGroup';

import '../styles/main.scss';

const NoExplorer = loadable(() => import(/* webpackChunkName: "NoExplorer" */'./scenes/NoExplorer'));

const Splash = ({ store }) => {
  useEffect(() => {
    const keyUp = e => {
      if (e.which === 27 && e.ctrlKey) {
        store.showContents();
      }
    };
    window.addEventListener('keyup', keyUp);
    return () => {
      window.removeEventListener('keyup', keyUp);
    };
  }, [true]);

  if (isIE() !== false) {
    return <NoExplorer />;
  }

  return (
    <Suspense fallback="">
      <BrowserRouter className="router" key="router">
        <FadeTransitionGroup>
          {store.session.initialized && !store.authenticated && <PublicView id="public" />}
          {store.session.initialized && store.authenticated && <UserView id="user" />}
          <BusyIndicator id="busy" show={!store.session.initialized} />
        </FadeTransitionGroup>
      </BrowserRouter>
    </Suspense>
  );
};

Splash.propTypes = {
  store: PropTypes.object.isRequired,
};

export default inject('store')(observer(Splash));
