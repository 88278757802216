import React from 'react';
// import PropTypes from 'prop-types';

import SvgIcon from '@material-ui/core/SvgIcon';

const ScaffoldingIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M2,10.2c-0.6-0.2-1-0.4-1-1C1.3,9,1.7,9.2,2,9c0-1.5,0-3.1,0-4.6C1.2,3.9,1.2,3.9,1,3.3c0.3-0.2,0.7,0,1-0.2
        c0.2-0.9,0-1.9,0.1-2.9c0.4-0.1,0.7,0,1.2,0c0,1,0,1.9,0,3c0.9,0.1,1.6,0.1,2.5,0c0.2-1,0-1.9,0.2-2.9c0.3-0.1,0.7-0.1,1.2,0
        c0,1,0,2,0,3c3.4,0,6.6,0,10,0c0.2-1,0-2,0.1-3c0.4-0.1,0.7-0.1,1,0c0.3,0.9-0.1,2,0.2,3c0.8,0.1,1.6,0,2.4,0c0.2-1,0-2,0.1-2.9
        c0.4-0.2,0.7-0.1,1.2-0.1c0.2,1,0,2,0.1,2.9c0.4,0.2,0.7,0,1,0.2c0,0.6-0.5,0.8-1,1c0,1.6,0,3.2,0,4.7c0.3,0.3,0.7,0,1,0.3
        c-0.1,0.6-0.5,0.8-1,1c0,1.6,0,3.2,0,4.7c0.3,0.3,0.7,0.1,1,0.2c0.1,0.7-0.5,0.9-1,1.1c0,1.6,0,3.2,0,4.7c0.3,0.2,0.7,0,1,0.2
        c0,0.7-0.5,0.8-1,1.1c-0.1,0.4-0.1,0.8,0,1.2c0.6,0.3,1.2,0,1.8,0.2c0.1,0.2,0.1,0.3,0.1,0.5c-8,0-15.9,0-23.9,0
        C0,24,0.1,23.8,0.2,23.6c0.6-0.2,1.2,0,1.8-0.1C2.2,23.1,2,22.7,2,22.3C1.6,22,1,21.9,1,21.1c0.3,0,0.6-0.1,1-0.1
        c0-1.6,0-3.1,0-4.8c-0.4-0.2-1-0.4-1-1.1c0.3-0.2,0.7,0,1-0.2C2,13.4,2,11.9,2,10.2z M7.1,8.5c3.3-1.4,6.5-2.7,9.9-4.1
        c-0.4-0.2-0.5-0.6-0.9-0.5c-0.3,0-0.4,0.3-0.6,0.3c-0.3,0-0.4-0.4-0.7-0.3c-0.3-0.1-0.4,0.4-0.8,0.3c-0.2,0-0.3-0.4-0.6-0.3
        c-0.3,0-0.4,0.4-0.7,0.4c-0.2-0.1-0.4-0.4-0.7-0.4c-0.3,0-0.4,0.3-0.6,0.4c-0.3,0-0.4-0.4-0.7-0.4c-0.3,0-0.4,0.4-0.7,0.4
        c-0.2,0-0.3-0.4-0.7-0.4C9,3.8,9,4.2,8.6,4.2C8.4,4.1,8.3,3.7,7.9,3.8C7.6,4,7.4,4.2,7.1,4.3C7.1,5.7,7.1,7,7.1,8.5z M7.2,10.3
        c3.4,1.4,6.6,2.7,9.9,4.1c0-1.5,0-2.8,0-4.2c-0.4,0-0.5-0.5-1-0.5c-0.2,0-0.3,0.4-0.6,0.4c-0.2,0-0.3-0.4-0.6-0.4
        c-0.3,0-0.4,0.4-0.7,0.4c-0.2,0-0.3-0.4-0.7-0.4c-0.3,0-0.4,0.4-0.7,0.3c-0.2-0.1-0.3-0.4-0.7-0.3c-0.3,0-0.3,0.3-0.6,0.4
        c-0.3,0-0.4-0.4-0.7-0.4c-0.3,0-0.4,0.4-0.7,0.4c-0.3,0-0.3-0.4-0.7-0.4C9,9.8,9,10.2,8.6,10.1c-0.2,0-0.3-0.4-0.6-0.4
        C7.7,9.8,7.6,10.1,7.2,10.3z M7.9,15.8c-0.3,0.1-0.5,0.5-0.8,0.5c0,1.4,0,2.7,0,4.1c3.3-1.4,6.5-2.7,9.8-4.1
        c-0.4-0.2-0.4-0.6-0.9-0.6c-0.2,0-0.3,0.4-0.6,0.4c-0.2-0.1-0.3-0.4-0.7-0.4c-0.3-0.1-0.3,0.4-0.7,0.4c-0.2,0-0.3-0.4-0.6-0.4
        c-0.3-0.1-0.4,0.4-0.7,0.4c-0.2-0.1-0.3-0.4-0.7-0.4c-0.3-0.1-0.3,0.4-0.7,0.4c-0.1-0.1-0.3-0.4-0.7-0.4
        c-0.3-0.1-0.3,0.4-0.7,0.4c-0.2,0-0.3-0.5-0.7-0.4c-0.3,0-0.3,0.4-0.7,0.4C8.4,16.1,8.3,15.7,7.9,15.8z M17.1,16.8
        c-3.3,1.4-6.5,2.7-9.7,4.1c0,0,0,0.1,0.1,0.1c3.2,0,6.4,0,9.7,0C17.1,19.6,17.1,18.3,17.1,16.8z M7.4,8.9c0,0,0,0.1,0,0.1
        c3.2,0,6.4,0,9.7,0c0-1.4,0-2.7,0-4.2C13.8,6.3,10.6,7.6,7.4,8.9z M7.1,15c3.3,0,6.5,0,9.7,0c0,0,0-0.1,0-0.1
        c-3.2-1.3-6.4-2.7-9.7-4C7.1,12.3,7.1,13.6,7.1,15z M15.4,22.2c-0.2-0.2-0.3-0.5-0.7-0.5c-0.3,0-0.3,0.4-0.6,0.4
        c-0.3,0-0.3-0.5-0.7-0.4c-0.3,0-0.3,0.4-0.6,0.4c-0.3-0.1-0.4-0.5-0.7-0.4c-0.3,0-0.3,0.4-0.7,0.4c-0.2-0.1-0.3-0.5-0.6-0.4
        c-0.3,0-0.4,0.5-0.7,0.4c-0.2-0.1-0.3-0.5-0.7-0.4C9,21.7,9,22.2,8.6,22.1c-0.2-0.1-0.3-0.5-0.8-0.4c-0.2,0.2-0.5,0.4-0.8,0.6
        c0,0.4,0,0.8,0.1,1.2c3.3,0,6.6,0,9.9,0c0.1-0.4,0.1-0.8,0-1.3c-0.4,0-0.5-0.5-0.9-0.5C15.8,21.7,15.8,22.1,15.4,22.2z M20.8,21
        c0-1.6,0-3.2,0-4.8c-0.8,0-1.6-0.1-2.5,0c0,1.6-0.1,3.2,0,4.8C19.3,21,20,21.1,20.8,21z M20.9,4.3c-0.9,0-1.6-0.1-2.5,0
        c0,1.6,0,3.2,0,4.8c0.8,0,1.6,0.1,2.5-0.1C20.9,7.4,20.9,5.9,20.9,4.3z M3.3,15.1c0.9,0,1.6,0.1,2.4,0c0-1.6,0-3.2,0-4.8
        c-0.8-0.1-1.6-0.1-2.4,0C3.3,11.9,3.3,13.4,3.3,15.1z M20.8,23.5c0.1-0.5,0.1-0.9,0-1.3c-0.8-0.1-1.6-0.1-2.3,0
        c-0.2,0.5-0.2,0.9,0,1.4C19.2,23.6,20,23.6,20.8,23.5z M3.4,22.2c-0.1,0.5-0.1,0.9,0,1.4c0.8,0,1.6,0.1,2.4-0.1
        c0.1-0.4,0.1-0.8,0-1.3C5,22.1,4.2,22.1,3.4,22.2z M18.5,11.5c-0.1,0.4-0.1,0.6,0,0.9c0.8,0.1,1.6,0.1,2.4,0
        c0-0.3,0.1-0.6-0.1-0.9C20,11.4,19.3,11.5,18.5,11.5z M18.5,12.8c-0.1,0.4-0.2,0.7,0,1c0.8,0,1.5,0.1,2.3,0
        c0.1-0.3,0.1-0.6,0-0.9C20,12.7,19.3,12.8,18.5,12.8z M18.5,11.2c0.8,0,1.6,0.1,2.4-0.1c0-0.3,0.1-0.5-0.1-0.8
        c-0.8-0.1-1.6-0.1-2.3,0C18.3,10.6,18.3,10.8,18.5,11.2z M18.5,15.1c0.8,0,1.6,0.1,2.4,0c0.1-0.3,0.1-0.6,0-0.9
        c-0.8-0.1-1.5-0.1-2.3,0C18.3,14.5,18.3,14.7,18.5,15.1z M5.7,20.1c-0.8-0.1-1.6-0.1-2.4,0c0,0.4-0.1,0.6,0.1,0.9
        c0.7,0.1,1.5,0.1,2.3,0C5.8,20.7,5.9,20.4,5.7,20.1z M5.7,18.8c-0.8-0.1-1.6-0.1-2.3,0c-0.1,0.3-0.1,0.6,0,0.9
        c0.8,0.1,1.5,0.1,2.3,0C5.9,19.4,5.8,19.1,5.7,18.8z M5.7,17.5c-0.8-0.1-1.6-0.1-2.3,0c-0.1,0.3-0.1,0.6,0,0.9
        c0.8,0,1.5,0.1,2.3,0C5.9,18.1,5.8,17.8,5.7,17.5z M5.7,16.2c-0.8-0.1-1.5-0.1-2.3,0c-0.1,0.4-0.1,0.6,0.1,1c0.7,0,1.5,0.1,2.3,0
        C5.8,16.8,5.9,16.5,5.7,16.2z M3.4,5.6C3.3,5.9,3.2,6.2,3.4,6.5c0.8,0.1,1.5,0.1,2.3,0c0.1-0.3,0.1-0.6,0-0.9
        C5,5.5,4.2,5.5,3.4,5.6z M5.7,6.9c-0.8,0-1.6-0.1-2.4,0c0,0.3-0.1,0.6,0.1,0.9c0.8,0,1.6,0.1,2.3,0C5.8,7.4,5.8,7.2,5.7,6.9z
         M3.4,9.1c0.8,0,1.6,0,2.4,0c0.1-0.3,0.1-0.5,0-0.9c-0.8,0-1.6-0.1-2.3,0C3.3,8.5,3.3,8.7,3.4,9.1z M3.4,4.3
        c0,0.4-0.1,0.6,0.1,0.9c0.7,0,1.5,0.1,2.3-0.1c0.1-0.3,0.1-0.6-0.1-0.8C4.9,4.2,4.2,4.2,3.4,4.3z"
    />
  </SvgIcon>
);

export default ScaffoldingIcon;
