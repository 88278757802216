const config = {
  domain: 'Scaffolding',
  apiUrl: process.env.REACT_APP_API || 'https://api.auth.ramirent.fi/api/scaffolding',
  url: process.env.REACT_APP_URL || 'https://auth.ramirent.fi',
  s3bucket: process.env.REACT_APP_S3 || 'scaffolding-prod',
  i18nDebug: false,
};

window.console.log(`Running as\n VERSION: ${process.env.REACT_APP_BUILDDATE}\n API: ${config.apiUrl}\n URL: ${config.url}`);

export default config;
