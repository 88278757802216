import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';
import { observer, inject } from 'mobx-react';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoleSelectionIcon from '@material-ui/icons/Group';
import ScanIcon from '@material-ui/icons/PhotoCamera';
import ScaffoldingIcon from '../ScaffoldingIcon';
import ResolveQR from '../ResolveQR';

import GenericView from './GenericView';
import './UserView.scss';

const Role = loadable(() => import(/* webpackChunkName: "Role" */'../scenes/Role'));
const Scaffoldings = loadable(() => import(/* webpackChunkName: "Scaffoldings" */'../scenes/Scaffoldings'));
const Scaffolding = loadable(() => import(/* webpackChunkName: "Scaffolding" */'../scenes/Scaffolding'));
const Scan = loadable(() => import(/* webpackChunkName: "Scan" */'../scenes/Scan'));
const Logout = loadable(() => import(/* webpackChunkName: "Scan" */'../scenes/Logout'));

const baseMenuItems = [
  {
    icon: <ExitToAppIcon />,
    link: '/logout',
    text: 'menu:logout',
  }, {
    icon: <ScanIcon />,
    link: '/scan',
    text: 'menu:scan',
  }, {
    icon: <ScaffoldingIcon />,
    link: '/',
    text: 'menu:scaffoldings',
  },
];

const UserView = ({ store }) => {
  const menuItems = baseMenuItems.slice();

  if (store.session.numRoles > 1) {
    menuItems.push({
      icon: <RoleSelectionIcon />,
      link: '/role',
      text: 'menu:roles',
    });
  }

  if (!store.session.user) {
    return <Redirect from={window.location.href} to="/public/login" />;
  }

  return (
    <GenericView menuItems={menuItems}>
      {!!store.session.role && (
        <Switch>
          {store.session.numRoles > 1 && (
            <Route path="/role" component={Role} />
          )}
          <Route path="/logout" component={Logout} />
          <Route path="/scan/:qr?" render={props => <Scan authenticated {...props} />} />
          <Route path="/scaffolding/:id/:section?" component={Scaffolding} />
          <Route path="/scaffoldings" component={Scaffoldings} />
          <Route component={ResolveQR} />
        </Switch>
      )}
      {!store.session.role && (
        <Switch>
          <Route path="/logout" component={Logout} />
          <Route component={Role} />
        </Switch>
      )}
    </GenericView>
  );
};

UserView.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.any,
};

UserView.defaultProps = {
  children: null,
};

export default withRouter(inject('store')(observer(UserView)));
