import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import config from './config';

import { LocalStorage } from './lib/Helper';

const i18nInit = async () => {
  const lsLang = LocalStorage.get('lang') || 'en';
  const cfg = {
    // resources: translations,
    lng: lsLang,
    fallbackLng: false,
    keySeparator: true,
    interpolation: {
      escapeValue: false,
    },
    ns: [
      'about',
      'error',
      'general',
      'inspection',
      'login',
      'menu',
      'recover',
      'role',
      'scaffolding',
      'scaffoldings',
      'scan',
    ],
    defaultNS: 'general',
    debug: config.i18nDebug,
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  };
  i18n
    .use(XHR)
    .use(initReactI18next)
    .init(cfg);
};

export default i18nInit;
