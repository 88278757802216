import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const BusyIndicator = ({ show }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="progresswrap">
      <CircularProgress size={100} color="secondary" />
    </div>
  );
};

BusyIndicator.propTypes = {
  show: PropTypes.bool,
};

BusyIndicator.defaultProps = {
  show: true,
};

export default BusyIndicator;
