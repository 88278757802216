import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import GenericAppBar from '../GenericAppBar';

import './UserView.scss';

const GenericView = ({
  title,
  children,
  menuItems,
  history,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const toggleMenu = state => () => {
    setMenuOpen(state !== undefined ? state : !menuOpen);
  };
  const itemClicked = i => () => {
    if (i.link !== undefined) {
      history.push(i.link);
    }
  };

  return (
    <div styleName="view">
      <GenericAppBar
        menuToggle={toggleMenu()}
        title={title}
      />
      <Drawer
        open={menuOpen}
        onClose={toggleMenu(false)}
        onClick={toggleMenu(false)}
      >
        <List>
          {menuItems.map(item => (
            <ListItem
              button
              onClick={itemClicked(item)}
              key={item.text}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={t(item.text)} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Grid
        container
        alignItems="center"
        direction="column"
        style={{ paddingTop: 10 }}
      >
        {children}
      </Grid>
    </div>
  );
};

GenericView.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  menuItems: PropTypes.array,
};

GenericView.defaultProps = {
  children: null,
  title: null,
  menuItems: [],
};

export default withRouter(GenericView);
