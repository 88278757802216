import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';

import RamiLogo from './RamiLogo';
import LanguageMenu from './LanguageMenu';

import './GenericAppBar.scss';

const GenericAppBar = ({ title, menuToggle, store }) => {
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);

  const toggleLanguageMenu = e => {
    setLanguageMenuOpen(!languageMenuOpen);
    setLanguageMenuAnchor(e.target);
  };
  const { t } = useTranslation();

  const closeLanguageMenu = () => setLanguageMenuOpen(false);

  return (
    <AppBar position="static">
      <Toolbar styleName="toolbar">
        <IconButton
          color="inherit"
          aria-label="Menu"
          onClick={menuToggle}
        >
          <MenuIcon />
        </IconButton>
        <Button onClick={toggleLanguageMenu}>
          {store.session.language}
        </Button>
        <LanguageMenu
          anchorEl={languageMenuAnchor}
          onClose={closeLanguageMenu}
          open={languageMenuOpen}
        />
        <Typography variant="h6" color="inherit" styleName="pagetitle">
          {title}
        </Typography>
        <Link to="/" styleName="logolink">
          <RamiLogo /> {t('general:appname')}
        </Link>
      </Toolbar>
    </AppBar>
  );
};

GenericAppBar.propTypes = {
  menuToggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  store: PropTypes.object.isRequired,
};

GenericAppBar.defaultProps = {
  title: null,
};

export default withRouter(inject('store')(observer(GenericAppBar)));
