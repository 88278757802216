import { createMuiTheme } from '@material-ui/core/styles';

export const sunflowerYellow = '#ffdf00';
export const lightBlue = '#4087f6';
export const lightGray = '#f1f1f1';
export const navy = '#003882';
export const mutedGray = 'gray';

export const primaryColor = sunflowerYellow;
export const lightColor = '#ffff54';
export const darkColor = '#c7ae00';
export const secondaryColor = navy;
export const secondaryLightColor = '#4861b2';
export const secondaryDarkColor = '#001455';
export const backgroundColor = lightGray;

export const createTheme = () => createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  fontFamily: 'Roboto, sans-serif',
  spacing: 1,
  palette: {
    primary: {
      light: lightColor,
      main: primaryColor,
      dark: darkColor,
      contrastText: '#000000',
    },
    secondary: {
      light: secondaryLightColor,
      main: secondaryColor,
      dark: secondaryDarkColor,
      contrastText: '#ffffff',
    },
  },
});
