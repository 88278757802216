import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const languages = {
  fi: 'FI',
  en: 'EN',
};

const LanguageMenu = ({
  anchorEl,
  open,
  onClose,
  store,
}) => {
  const selectLanguage = key => () => {
    store.session.language = key; // eslint-disable-line no-param-reassign
    onClose();
  };
  const languageList = [store.session.language];
  Object.entries(languages).forEach(arr => {
    if (arr[0] !== store.session.language) {
      languageList.push(arr[0]);
    }
  });
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
    >
      {languageList.map(key => (
        <MenuItem
          onClick={selectLanguage(key)}
          key={key}
          className={store.session.language === key ? 'selected' : null}
        >
          {languages[key]}
        </MenuItem>
      ))}
    </Menu>
  );
};

LanguageMenu.propTypes = {
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
};

LanguageMenu.defaultProps = {
  anchorEl: null,
};

/* Container */

export default inject('store')(observer(LanguageMenu));
