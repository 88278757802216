import React from 'react';
import { hot } from 'react-hot-loader';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';

import Splash from './Splash';
import { createTheme } from '../lib/Theme';

const theme = createTheme();

const Application = () => (
  <MuiThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <Splash />
    </StylesProvider>
  </MuiThemeProvider>
);

export default hot(module)(Application);
