import { types, getParent } from 'mobx-state-tree';
import i18n from 'i18next';
import FetchRequest from '../../lib/FetchRequest';
import { LocalStorage } from '../../lib/Helper';
import i18nInit from '../../i18n';

// import User from './User';

/* eslint-disable no-param-reassign */
const Session = types
  .model('Session', {
    user: types.maybeNull(types.frozen()),
    role: types.maybeNull(types.frozen()),
    created: types.maybeNull(types.Date),
    token: types.maybeNull(types.string),
    busy: false,
    initialized: false,
    lang: 'en',
  })

  .views(self => ({
    get language() {
      return self.lang;
    },
    set language(l) {
      self.setLanguage(l);
    },
    get roles() {
      if (!self.user || !self.user.roles || !self.user.roles.all) {
        return null;
      }
      return self.user.roles;
    },
    get numRoles() {
      if (!self.user || !self.user.roles || !self.user.roles.all) {
        return null;
      }
      return Object.keys(self.user.roles.all).length;
    },
    get firstRole() {
      if (!self.user || !self.user.roles || !self.user.roles.all) {
        return null;
      }
      return Object.keys(self.user.roles.all)[0];
    },
    get admin() {
      return (self.role
        && self.user
        && self.user.adminroles
        && self.user.adminroles.Scaffolding
        && self.user.adminroles.Scaffolding.indexOf(self.role) > -1);
    },
  }))

  .actions(self => ({
    setToken(t) {
      self.token = t;
    },

    setRole(id) {
      if (id === null) {
        self.role = null;
        FetchRequest.setRole(null);
        LocalStorage.clear('role');
        return true;
      }
      if (self.user && self.user.roles.all && self.user.roles.all[id]) {
        self.role = parseInt(id, 10);
        FetchRequest.setRole(self.role);
        LocalStorage.set('role', self.role);
        return true;
      }
      return false;
    },

    setBusy() {
      if (self.busy) {
        return false;
      }
      self.busy = true;
      return true;
    },

    clearBusy() { self.busy = false; },

    setInitialized() { self.initialized = true; },

    setLanguage(l) {
      self.lang = l;
      i18n.changeLanguage(l);
      LocalStorage.set('lang', l);
    },

    clear() {
      self.user = null;
      self.created = null;
      self.token = null;
      self.role = null;
      LocalStorage.clear('role');
      LocalStorage.clear('token');
      FetchRequest.clearRole();
      FetchRequest.clearToken();
    },

    logout(success = null) {
      if (self.busy) {
        return;
      }
      FetchRequest
        .post('/logout')
        .then(() => {
          self.clear();
          if (success) {
            success();
          }
        });
    },

    setUserToken(user, token) {
      self.token = token;
      self.user = user;
      LocalStorage.set('token', token);
      FetchRequest.setToken(token);
    },

    login(data, failure, success = null) {
      if (!self.setBusy()) { return; }
      FetchRequest
        .post('/login', data)
        .then(r => {
          if (r.error) {
            self.clear();
            failure(r.message);
          } else {
            self.setUserToken(r.message.me, r.message.access_token);
            if (self.numRoles === 1) {
              self.setRole(self.firstRole);
            }
            if (success) {
              success(r.message);
            }
          }
          self.clearBusy();
        });
    },

    async status() {
      const result = await FetchRequest.post('/status');
      if (result.code !== 200) {
        self.clear();
        return false;
      }
      self.setUserToken(result.message.me, result.message.access_token);
      return result.message;
    },

    async afterCreate() {
      await i18nInit();
      const oldToken = LocalStorage.get('token');
      // eslint-disable-next-line no-useless-escape
      if (!oldToken || !String(oldToken).match(/^[a-zA-Z0-9\.\-_]+$/)) {
        // console.log('Session.afterCreate(): No / malformed token');
        self.clear();
        self.setInitialized();
        return;
      }
      self.setToken(oldToken);
      FetchRequest.setToken(oldToken);
      await self.status();
      if (getParent(self).authenticated) {
        const oldRole = LocalStorage.get('role');
        if (oldRole) {
          self.setRole(oldRole);
        } else if (self.numRoles === 1) {
          self.setRole(self.firstRole);
        }
      }
      if (LocalStorage.get('lang')) {
        self.language = LocalStorage.get('lang');
      }
      self.setInitialized();
    },

  }));
/* eslint-enable no-param-reassign */

export default Session;
