import React from 'react';
import PropTypes from 'prop-types';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

const FadeTransitionGroup = ({ children }) => (
  (children && children.length > 0)
    ? (
      <TransitionGroup>
        {children
          .filter(item => item)
          .map(item => (
            <CSSTransition
              timeout={500}
              classNames="fade"
              key={item.props.id}
            >
              {item}
            </CSSTransition>
          ))
        }
      </TransitionGroup>
    ) : null
);

FadeTransitionGroup.propTypes = {
  children: PropTypes.array,
};

FadeTransitionGroup.defaultProps = {
  children: [],
};

export default FadeTransitionGroup;
