import { types } from 'mobx-state-tree';
import Session from './models/Session';
// import ObjectStore from './models/ObjectStore';
// import SingleObject from './models/SingleObject';

const RootStore = types
  .model('RootStore', {
    session: types.optional(Session, {}),
    flags: types.optional(types.map(types.boolean), {}),
  })
  .views(self => ({
    get authenticated() {
      return (self.session.user !== null && self.session.user !== undefined);
    },
  }))
  .actions(self => {
    const showContents = () => {
      window.console.log(self.toJSON());
    };
    return {
      showContents,
    };
  });

export default RootStore;
