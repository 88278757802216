import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'mobx-react';
import {
  // getSnapshot,
  destroy,
  onSnapshot,
} from 'mobx-state-tree';

import Application from './components/Application';
import config from './config';
import Store from './store';

import './styles/main.scss';

let theStore = null;
let snapshotListener = null;

const renderApp = (App, rootstore) => {
  ReactDOM.render(<Provider store={rootstore}><CssBaseline /><Application /></Provider>, document.getElementById('root'));
};

const createStore = snapshot => {
  if (snapshotListener) {
    snapshotListener();
  }
  if (theStore) {
    destroy(theStore);
  }
  theStore = Store.create(snapshot);
  snapshotListener = onSnapshot(theStore, shot => {
    localStorage.setItem(config.localStorageKey, JSON.stringify(shot));
  });
  // window.rootstore = theStore;
  return theStore;
};

if (false && localStorage.getItem(config.localStorageKey)) {
  const storeObj = JSON.parse(localStorage.getItem(config.localStorageKey));
  window.console.info('Creating store from local storage', storeObj);
  theStore = createStore(storeObj);
  // theStore.session.me();
} else {
  window.console.info('Creating new store');
  theStore = createStore();
}

// theStore.flush();
renderApp(Application, theStore);
