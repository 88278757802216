import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

const ResolveQR = ({ store, location }) => {
  let url = '/public/login';
  const normalizedLocation = window.location.href.replace(/^https:/, 'http:');
  if (store.session.initialized && store.authenticated) {
    url = '/scaffoldings';
  }
  if (location.pathname.match(/^\/qr[a-z0-9]{5,}/i)) {
    if (store.session.initialized && store.authenticated) {
      url = `/scan/${encodeURIComponent(normalizedLocation)}`;
    } else {
      if (window.location.hostname !== 'eteline.ramirent.fi' && window.location.hostname !== 'dev.eteline.ramirent.fi') {
        window.location = `http://eteline.ramirent.fi${location.pathname}`;
      }
      url = `/public/scan/${encodeURIComponent(normalizedLocation)}`;
    }
  }
  return <Redirect to={url} />;
};

ResolveQR.propTypes = {
  location: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

export default withRouter(inject('store')(observer(ResolveQR)));
